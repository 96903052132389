import { Link } from "react-router-dom";
import logoImg from "../../assets/images/home/logo.png";
import maintenanceImg from "../../assets/images/maintenance-icon.png";

const MaintenanceMode = () => {
   return (
      <div className="maintenance-mode">
         <div className="login-logo-outer">
            <div className="login-logo-inner">
               <Link href="/"><img src={logoImg}/></Link>
            </div>
         </div>
         <div className="maintenance-mode-content">
            <img className="maintenance-img" src={maintenanceImg} />
            <h2>Vizam is currently under maintenance.</h2>
         </div>
      </div>
   )
}

export default MaintenanceMode;